<template>
	<div class="psgContainer">
		<div class="adultContainer">
			<b-container>
				<b-row>
					<b-col style="display: flex; justify-content: flex-start">
						<img class="back" src="../assets/back.svg" @click="$router.go(-1)" />
					</b-col>
				</b-row>
				<b-form class="psgForm" @submit.prevent="processPsgDetails(index)" validated>
					<div class="row" v-if="error">
						<div class="col-md-12" style="display: flex; justify-content:center; margin: 20px 0;">
							<div class="error">{{ error }}</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3" v-for="(adult, index) in data.adults" :key="index">
							<div class="col" style="font-weight: 700; color: #212121; font-family='Open Sans', sans-serif;">
								ADULT {{ count(index) }}</div>
							<div class="row adultRow" style="display:flex; flex-direction:column">
								<div class="col">
									<b-form-group label="Name*" label-for="adult_name">
										<b-form-input placeholder="Enter Passenger Name" v-model="passengers.adult.name[index]"
											:state="validation" required></b-form-input>
									</b-form-group>

								</div>
								<div class="col-md-12">
									<b-form-group label="Date Of Birth*" label-for="adult_dob">
										<b-form-datepicker class="form-control input-focus" locale="en-US"
											:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
											v-model="passengers.adult.dob[index]" placeholder="" :hide-header="hideHeader"
											:state="validation" required></b-form-datepicker>
									</b-form-group>
								</div>
								<div class="col-md-12">
									<b-form-group label="Email*" label-for="adult_email">
										<b-form-input placeholder="Enter Passenger Email" v-model="passengers.adult.email[index]"
											type="email" :state="validation" required></b-form-input>
									</b-form-group>
								</div>
								<div class="col-md-12">
									<b-form-group label="Contact Number*" label-for="adult_contact">
										<b-form-input placeholder="Enter Passenger Contact"
											v-model="passengers.adult.contact[index]" :state="validation" type="number" required>
										</b-form-input>
									</b-form-group>
								</div>
							</div>
						</div>
					</div>
					<hr v-if="data.child" />
					<!-- CHILDS  -->
					<div class="row">
						<div class="col" v-for="(adult, index) in data.child" :key="index">
							<div class="col" style="font-weight: 700; color: #212121; font-family='Open Sans', sans-serif;">
								Child {{ index+1 }}</div>
							<div class="row adultRow" style="display:flex; flex-direction:column">
								<div class="col-md col-xs-12">
									<b-form-group label="Name*" label-for="child_name">
										<b-form-input placeholder="Enter Passenger Name" v-model="passengers.child.name[index]"
											:state="validation" required></b-form-input>
									</b-form-group>

								</div>
								<div class="col-md col-xs-12">
									<b-form-group label="Date Of Birth*" label-for="child_dob">
										<b-form-datepicker class="form-control input-focus" locale="en-US"
											:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
											v-model="passengers.child.dob[index]" :state="validation" placeholder=""
											:hide-header="hideHeader" required></b-form-datepicker>
									</b-form-group>
								</div>
								<div class="col-md col-xs-12">
									<b-form-group label="Email*" label-for="child_email">
										<b-form-input placeholder="Enter Passenger Email" v-model="passengers.child.email[index]"
											:state="validation" class="form-control input-focus" type="email"></b-form-input>
									</b-form-group>
								</div>
								<div class="col-md col-xs-12">
									<b-form-group label="Contact Number*" label-for="child_contact">
										<b-form-input placeholder="Enter Passenger Contact"
											v-model="passengers.child.contact[index]" :state="validation" type="number" required>
										</b-form-input>
									</b-form-group>
								</div>
							</div>
						</div>
					</div>
					<hr v-if="data.infant" />
					<!-- INFANTS  -->
					<div class="row">
						<div class="col" v-for="(adult, index) in data.infant" :key="index">
							<div class="col" style="font-weight: 700; color: #212121; font-family='Open Sans', sans-serif;">
								Infant {{ index+1 }}</div>
							<div class="row adultRow" style="display:flex; flex-direction:column">
								<div class="col-md col-xs-12">
									<b-form-group label="Name*" label-for="infant_name">
										<b-form-input placeholder="Enter Passenger Name" v-model="passengers.infant.name[index]"
											:state="validation" required></b-form-input>
									</b-form-group>

								</div>
								<div class="col-md col-xs-12">
									<b-form-group label="Date Of Birth*" label-for="infant_dob">
										<b-form-datepicker class="form-control input-focus" locale="en-US"
											:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
											v-model="passengers.infant.dob[index]" :state="validation" placeholder=""
											:hide-header="hideHeader" required></b-form-datepicker>
									</b-form-group>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12" style="display: flex; justify-content:center; margin: 20px 0;">
							<b-button variant="success" type="submit" @submit.prevent="processPsgDetails(index)">Process
								Passenger Details</b-button>
						</div>
					</div>
				</b-form>
			</b-container>
		</div>


	</div>
</template>



<script>

export default {
  
  name: "Passenger",

  data() {
    return {
      data: {},
      passengers: {
        adult: {
          name: [],
          dob: [],
          email: [],
          contact: []
        },
        child: {
          name: [],
          dob: [],
          email: [],
          contact: []
        },
        infant: {
          name: [],
          dob: [],
        }
      },
      isPsgComplete: false,
      index: '',
      hideHeader: false,
      error: "",
      validated: false,
    }
  },
  computed: {
    validation() {
      this.error = "Please Fill in all the details!"
    },
  },
  mounted() {
    this.index = window.location.pathname.split('/passenger/', -1)[1]
    this.data = this.$store.state.cart.cartItems[this.index]
  },

  methods: {
    async processPsgDetails() {
      let vm = this
      if(_.isEmpty(this.passengers.adult.dob[vm.index])) {
        return this.error = "Adult Date Of Birth is not present."
      } else {
        this.passengers.isPsgComplete = true
        this.passengers.index = this.index
        await vm.$store.dispatch('psgdetails/setPassengers', vm.passengers)
        this.error = "Added Passenger Details Successfully"
      }
      return setTimeout(() => {
        this.$router.push('/cart')
      }, 2000)
    },
    count(index) {
      this.index = index
      return index + 1
    }
  }
}

</script>


<style lang="scss" scoped>
.psgContainer {
  padding: 20px 0;
  input {
    border-radius: 6px;
    background: #ECEFF1!important;
  }

  #adult_dob__dialog_ #adult_dob__value_ {
    display: none!important;
  }
}

div.adultRow > * {
  margin: 10px 0px;
}



.error {
  width: 100%;
  padding: 10px 0;
  color: #212212;
  border-radius: 6px;
  background: #FFCDD2;
  text-align: center;
  font-weight: 500;
}

</style>